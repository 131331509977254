<script>
export default {
  props: ['fixed'],
  computed: {
    bottomNav() {
      return () => import(`@/components/Themes/${this.profile?.theme?.name ?? 'default'}/BottomNav`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="bottomNav" :fixed="fixed" v-if="bottomNav"></component>
</template>